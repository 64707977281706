require('./util')
require('throw');
require('drawsvg');
require('draggable');
require('scrollPlugin');
require("./../../bower_components/gsap/src/uncompressed/TweenMax.js");
var H5F = require('h5f');
var Wallop = require('Wallop');
var swiper = require('swiper');
var draggable = [];

//var scroller = require('./scroll');

var menu = $('#menuBtn'),
    $window = $(window), slider1, slider2
    
menu.on ('click', function(e){
  $('.main-nav').toggleClass('is-open');
  menu.toggleClass('is-active');
})

$('.main-nav a, .scroll-to').on ('click', function(e){

  e.preventDefault()
  $('.main-nav').removeClass('is-open');
  menu.removeClass('is-active');
  
  var to = '#' +  $(this).attr('rel')
  var section = $(to).offset().top
  TweenLite.to(window, 1.27, {scrollTo:{ y: section, autoKill: false }, ease:Power4.easeInOut});
  
})

$('.more-stuff').on ('click', function(e){
  $('.more').addClass('is-visible')
})



TweenMax.set('#path', { drawSVG: '0% 0%'});
TweenMax.to('.loader', 1, {autoAlpha:1, scale:1});
TweenMax.to('#path', 2, { drawSVG: true, ease: Linear.easeOut, onComplete: exec});
TweenMax.to('.loader', 1, {autoAlpha:0, scale:1, delay:2});
TweenMax.to('.preload', 1, {autoAlpha:0, delay:2});


function exec() {

    $('body').addClass('loaded')

}

function initSlider1() {
  slider1 = new Swiper ('#slider1', {
    autoplay: 6300,
    effect: 'fade',
    speed: 1300,
    fade: {
      crossFade: true
    },
    observer: true,
    observeParents: true,
    loop: true
  })
}

function initSlider2() {
  slider2 = new Swiper ('#slider2', {
    autoplay: 4100,
    effect: 'fade',
    speed: 1300,
    fade: {
      crossFade: true
    },
    observer: true,
    observeParents: true,
    loop: true
  })
}



var mq = window.matchMedia('all and (max-width: 767px)');
mq.addListener(WidthChange);
WidthChange(mq)

// media query change
function WidthChange(mq) {
  if (mq.matches) {
    $.each(draggable, function(index, el) {
      
      TweenLite.set('.draggable',{clearProps:"x,y,zIndex, left, top, width, height"});
      el.kill()
    })
    
    var slider1 = $('#slider1')[0].swiper;
    var slider2 = $('#slider2')[0].swiper;
    
    if(slider1){
      slider1.destroy(true, true);
    }
    if(slider2){
      slider2.destroy(true, true);
    }
    
    $(".video-container").empty().append("<iframe src='https://player.vimeo.com/video/71326939?title=0&byline=0&portrait=0' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>");
    } 
    
    else {
    draggable = Draggable.create(".draggable", {
      type:"x,y",
      edgeResistance:0.9,
      bounds:"#work",
      zIndexBoost:false,
      throwProps:true
    });
    
    initSlider1()
    initSlider2()
    
    $(".video-container").empty()
  }

}

$(document).mouseup(function (e)
{
    var container = $(".more");

    if (!container.is(e.target) // if the target of the click isn't the container...
        && container.has(e.target).length === 0) // ... nor a descendant of the container
    {
        container.removeClass('is-visible');
    }
});

$('#contact-form').on('click', function () {
  
})

function submitForm(event){
   event.preventDefault();
  
    var formEl = $(this);
    
    var submitButton = $('button[type=submit]', formEl);
  
    $.ajax({
      type: 'POST',
      url: formEl.prop('action'),
      accept: {
        javascript: 'application/javascript'
      },
      data: formEl.serialize(),
      beforeSend: function() {
        submitButton.prop('disabled', 'disabled');
        submitButton.text('SENDING…')
      },
      success: function(data) {
        $('#contact-form').addClass('done')
        $('.contact-form').addClass('done')
        submitButton.prop('disabled', false);
        
      }
    });
}

H5F.setup(document.getElementById("contact-form"),{ 
  onSubmit: submitForm
})

  
  
 
  
//  var lslider = document.querySelector('.slider1');
//  wallop = new Wallop(lslider); 
//  
//  var mslider = document.querySelector('.slider2');
//  mwallop = new Wallop(mslider); 
//  
//  TweenMax.delayedCall(3, slide);
//  
//  function slide() { 
//      wallop.next()
//      TweenMax.delayedCall(3, slide);
//  }
//
//
// TweenMax.delayedCall(4, slide2);
// 
// function slide2() { 
//     mwallop.next()
//     TweenMax.delayedCall(4, slide2);
// }
  
  var moveMag = new TimelineMax({paused: true, onReverseComplete: reverseComplete, onComplete: complete});
  
  function reverseComplete() {
    
    var slider = $('.swiper-container', '.gallery-opened')
    var id = slider[0].id
    var swiper = $('#' + id)[0].swiper;
    var autoplay
    var speed
    if(id == "slider1"){
      autoplay = 6300
    }
    else {
      autoplay = 4300
    }
    
    $('.gallery').removeClass("gallery-opened")
    
    swiper.off('click')
    swiper.destroy(true, true);
     new Swiper ('#' + id, {
      autoplay: autoplay,
      effect: 'fade',
      speed: 1300,
      fade: {
        crossFade: true
      },
      observer: true,
      observeParents: true,
      loop: true
    })
    
  }
  
  function complete() {
  
    var slider = $('.swiper-container', '.gallery-opened')
    var id = slider[0].id
    var swiper = $('#' + id)[0].swiper;
    swiper.destroy(true, true);
    
    var swiper = new Swiper ('#' + id, {
      effect: 'slide',
      slidesPerView: 'auto',
      keyboardControl: true,
      speed: 1100,
      centeredSlides: true,
      spaceBetween: 10,
      observer: true,
      observeParents: true,
      loop: true
    })
    
    swiper.on('click', function () {
      var clicked = swiper.clickedIndex
      var active = swiper.activeIndex
      
      if(clicked < active ) {
        swiper.slidePrev()
      }
      else if(clicked > active){
        swiper.slideNext()
      }
    })
  }

  
  var videoMag = new TimelineMax({paused: true, onComplete: function () {
      
      
      $(".video-container").empty().append("<iframe src='https://player.vimeo.com/video/71326939?autoplay=1&title=0&byline=0&portrait=0' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>");
      
       TweenMax.fromTo(".video-gallery .video-container", 1, {opacity: 0}, {opacity: 1, delay: 1});
       TweenMax.to(".video-gallery .gallery-container", 1, {opacity: 0});
  
  }});
  
  
  function moveMagGenerate(gallery) {
      
      var wW = $(window).width(), wH = $(window).height();
      moveMag.clear()
        .to(gallery, .8, {  
          xPercent: -50,
          yPercent: -50,
          top: '50%',
          left: '50%',
          x: 0,
          y: 0,
          ease: Power3.easeInOut,
          zIndex: 999999
        })
        .to(gallery, .8, {  xPercent: -50, yPercent: -50, x: 0, y: 0, width: wW, height: wH, ease: Power4.easeInOut })
      ;
    }
    
  function videoMagGenerate(gallery) {
      
      var wW = $(window).width(), wH = $(window).height();
      videoMag.clear()
        .to(gallery, .8, {  
          xPercent: -50,
          yPercent: -50,
          top: '50%',
          left: '50%',
          x: 0,
          y: 0,
          ease: Power3.easeInOut,
          zIndex: 999999
        })
        .to(gallery, .8, {  xPercent: -50, yPercent: -50, x: 0, y: 0, width: wW, height: wH, ease: Power4.easeInOut })
      ;
    }

//  
//  
//  $("#el4 .arrow-right").on('click', function() {
//    
//    
//    TweenMax.killDelayedCallsTo(slide2)
//    mwallop.previous()
//  
//  })
//  
//  $("#el4 .arrow-left").on('click', function() {
//    
//    TweenMax.killDelayedCallsTo(slide2)
//    mwallop.next()
//  
//  })
  
  
  
  $(".gallery .arrow").on('click', function() {
    var self = $(this);
    var gallery = self.parents('.gallery')
    var work = $('#work')
    var body = $('body')
    var section = work.offset().top
    TweenLite.to(window, .6, {scrollTo:{ y: section, autoKill: false }, ease:Power4.easeInOut});
    
    if(self.hasClass("clicked")) {
       moveMag.reverse();
       Draggable.get("#el4").enable();
       Draggable.get("#el2").enable();
      
       body.removeClass("has-gallery")
       self.removeClass("clicked");
       //TweenMax.delayedCall(3, slide);
       
    }
    else if(!self.hasClass("clicked")) {
       
       Draggable.get("#el4").disable();
       Draggable.get("#el2").disable();
       console.log('disable')
       gallery.addClass("gallery-opened")
       moveMagGenerate(gallery);
       moveMag.play();
       
       
       body.addClass("has-gallery")
       self.addClass("clicked");
       //TweenMax.killDelayedCallsTo(slide)
       
    }
  })
  
  $(".video-gallery .arrow").on('click', function() {
    var self = $(this);
    var gallery = self.parents('.video-gallery')
    var work = $('#work')
    var body = $('body')
    var section = work.offset().top
    TweenLite.to(window, .6, {scrollTo:{ y: section, autoKill: false }, ease:Power4.easeInOut});
    
    if(self.hasClass("clicked")) {
       videoMag.reverse();
       Draggable.get(".video-gallery").enable();
       gallery.removeClass("gallery-opened")
       body.removeClass("has-gallery")
       self.removeClass("clicked");
       $(".video-container").empty();
       TweenMax.to(".video-gallery .gallery-container", 1, {opacity: 1});
       
    }
    else if(!self.hasClass("clicked")) {
       videoMagGenerate(gallery);
       videoMag.play();
       Draggable.get(".video-gallery").disable();
       gallery.addClass("gallery-opened")
       body.addClass("has-gallery")
       self.addClass("clicked");
  
       
    }
  })
  
  
  
  
  
  
  $(window).on('resize', function (e) {
    var wW = $(window).width(), wH = $(window).height();
    $(".gallery-opened").css({width:wW, height: wH})
    var work = $('#work')
    var body = $('body')
    
    if(body.hasClass('has-gallery')){
      var section = work.offset().top
      TweenLite.to(window, .6, {scrollTo:{ y: section, autoKill: false }, ease:Power4.easeInOut});
    }
    
        
  }).trigger('resize')
  
  
  
  
  
  